import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
  resources: {
    "pt-BR": {
      translations: {
        index: {
          back: "Voltar",
        },

        hero: {
          title: "Condições Gerais da sua assistência",
        },
        body: {
          title:
            "Comece sua aventura com tranquilidade. Aqui você encontrará as Condições Gerais que regem o nosso serviço. Baixe-os agora e viaje com tranquilidade em cada etapa de sua jornada.",
          cta1: "Eu não sei que plano eu tenho",
        },
      },
    },
    es: {
      translations: {
        index: {
          back: "Volver",
        },

        hero: {
          title: "Condiciones Generales de tu asistencia",
          cta: "Ingresar",
        },
        body: {
          title: "¿Quieres darte de alta para trabajar con nosotros?",
          cta1: "Unirme al programa de afiliados",
          cta2: "Soy una agencia",
        },
      },
    },
    "es-AR": {
      translations: {
        index: {
          back: "Volver",
        },

        hero: {
          title: "Condiciones Generales de tu asistencia",
          cta: "Ingresar",
        },
        body: {
          title:
            " Comenzá tu aventura con tranquilidad. Aquí encontrarás las Condiciones Generales que rigen nuestro servicio. Descargalas ahora y viajá con tranquilidad cada paso de tu viaje.",
          cta1: "No sé qué plan tengo",
        },
      },
    },
  },
  lng: "es-AR",
  fallbackLng: "pt-BR",
  ns: ["translations"],
  defaultNS: "translations",
});
export default i18n;
