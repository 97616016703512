import "../assets/css/tailwind.css";
import "../assets/css/core.css";
import React from "react";
import feature1 from "../assets/img/Feature1.svg";
import feature1Desktop from "../assets/img/Feature1-desktop.svg";
import { useTranslation } from "react-i18next";

const CgpComponent = () => {
  const { t } = useTranslation();

  let url = window.location.href;
  const geo = url.split("/")[3] || "ar";

  return (
    <div className="home-container bg-[#EFF1F4]">
      <section className="home-features  max-w-[630px] xl:max-w-[1010px] mx-auto md:pt-20 ">
        <button className="mx-0" type="button">
          <div class="home-btn text-365-violet-hard text-base font-semibold mb-16 flex flex-row items-center pl-4  mt-6 gap-2 ">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289C10.0976 3.68342 10.0976 4.31658 9.70711 4.70711L5.41421 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41421 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
                fill="#675CE5"
              />
            </svg>
            <h3 className="text-[#675CE5]" data-dt="products_back2home">
              <a href={`/${geo}`}>{t("index.back")}</a>
            </h3>
          </div>
        </button>
        <h1 className="font-titillium text-3xl md:text-3xl xl:text-4xl text-[#2F6DE0] text-center font-bold pb-12 px-1 ">
          {t("hero.title")}
        </h1>
        <div className="feature-box-1 flex flex-col place-items-center  gap-y-4  px-4 pb-24  md:pb-24  ">
          <img src={feature1} alt="Banner" className=" md:hidden block pb-8" />
          <img
            src={feature1Desktop}
            alt="Banner"
            className="hidden md:block pb-8"
          />

          <div className="lg:max-w-[765px] md:max-w-[477px] pt-4 lg:pt-4">
            <h3 className="font-titillium text-xl max-w-[600px] mx-auto text-center  font-normal pb-12">
              {t("body.title")}
            </h3>
            <div className="font-titillium text-center flex flex-col-reverse lg:flex-row gap-4 justify-center items-center">
              {geo === "ar" && (
                <div className="underline text-blue-500 font-semibold">
                  <a
                    target="_blank"
                    href="https://assistcdn.s3.us-west-1.amazonaws.com/assets/site/ccgg/Condiciones_Generales_y_Particulares-Planes_Essential_Trip_Wander_Travel_Europe_Care_Premium_Cover_Safe+Travel_Multitrip_Multitrip_Plus-.pdf"
                  >
                    CONDICIONES GENERALES | ESSENTIAL TRIP, WANDER TRAVEL,
                    EUROPE CARE, PREMIUM COVER, SAFE TRAVEL, PLANES MULTITRIP Y
                    MULTITRIP PLUS
                  </a>{" "}
                  <br />
                  <br />
                  <a
                    target="_blank"
                    href="https://assistcdn.s3.us-west-1.amazonaws.com/assets/site/ccgg/Condiciones_Generales_y_Particulares-Planes_World_Cover_Global_Backpacker_Safe_Exchange_World_Explorer_Argentina-.pdf"
                  >
                    CONDICIONES GENERALES | WORLD COVER, GLOBAL BACKPACKER, SAFE
                    EXCHANGE Y WORLD EXPLORER
                  </a>
                  <br />
                  <br />
                  <a
                    target="_blank"
                    href="https://assistcdn.s3.us-west-1.amazonaws.com/assets/site/ccgg/Plantilla-bases-legales-concursos-Twitter+ASSIST365.docx.pdf"
                  >
                    Bases y Condiciones Concurso Twitter (Mayo 2024)
                  </a>
                </div>
              )}

              {geo === "br" && (
                <div className="underline text-blue-500 font-semibold">
                  <a
                    target="_blank"
                    href="https://assistcdn.s3.us-west-1.amazonaws.com/assets/site/ccgg/Condic%C3%B5es_Gerais_Planos_Essential_Trip_Wander_Travel_Europe_Care_Premium_Cover_Multitrip_Multitrip_Plus.pdf"
                  >
                    CONDIÇÕES GERAIS | PLANOS ESSENTIAL TRIP, WANDER TRAVEL,
                    EUROPE CARE, PREMIUM COVER, PLANES MULTITRIP E MULTITRIP
                    PLUS
                  </a>{" "}
                  <br />
                  <br />
                  <a
                    target="_blank"
                    href="https://assistcdn.s3.us-west-1.amazonaws.com/assets/site/ccgg/Condicoes_Gerais-Planos_World_Cover_Safe_Travel_Global_Backpacker_Safe_Exchange_World_Explorer.pdf"
                  >
                    CONDIÇÕES GERAIS | PLANOS WORLD COVER, GLOBAL BACKPACKER,
                    SAFE EXCHANGE, WORLD EXPLORER
                  </a>
                </div>
              )}

              {geo !== "br" && geo !== "ar" && (
                <div className="underline text-blue-500 font-semibold">
                  <a
                    target="_blank"
                    href="https://assistcdn.s3.us-west-1.amazonaws.com/assets/site/ccgg/Condiciones_Generales_y_Particulares-Planes_Essential_Trip_Wander_Travel_Europe_Care_Premium_Cover_Multitrip_Multitrip_Plus.pdf"
                  >
                    CONDICIONES GENERALES | ESSENTIAL TRIP, WANDER TRAVEL,
                    EUROPE CARE, PREMIUM COVER, SAFE TRAVEL, PLANES MULTITRIP Y
                    MULTITRIP PLUS
                  </a>{" "}
                  <br />
                  <br />
                  <a
                    target="_blank"
                    href="https://assistcdn.s3.us-west-1.amazonaws.com/assets/site/ccgg/Condiciones_Generales_y_Particulares-Planes_World_Cover_Safe_Travel_Global_Backpacker_Safe_Exchange_World_Explorer.pdf"
                  >
                    CONDICIONES GENERALES | WORLD COVER, GLOBAL BACKPACKER, SAFE
                    EXCHANGE Y WORLD EXPLORER
                  </a>{" "}
                  <br />
                  <br />
                  <a
                    target="_blank"
                    href="https://assistcdn.s3.us-west-1.amazonaws.com/assets/site/ccgg/Plantilla-bases-legales-concursos-Twitter+ASSIST365.docx.pdf"
                  >
                    Bases y Condiciones Concurso Twitter (Mayo 2024)
                  </a>
                </div>
              )}
            </div>

            <div className="font-titillium text-center flex flex-col-reverse lg:flex-row gap-4 justify-center items-center mt-8">
              <a
                className="font-titillium text-base font-semibold w-[301px] h-[48px]  text-center  md:w-[343px]  justify-center  bg-[#7BD0C2] rounded-lg flex items-center"
                href={"https://assist-365.com/mi-voucher"}
              >
                {t("body.cta1")}
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CgpComponent;
